import { DrawerSection } from '../../components/AppDrawer';
import { ReactComponent as PaymentIcon } from 'assets/receipt.svg';
import { ReactComponent as FinancialIcon } from 'assets/payment.svg';
import { ReactComponent as CreditCardIcon } from 'assets/credit-card.svg';
import { ReactComponent as ForecastIcon } from 'assets/forecast.svg';

import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CasesRoundedIcon from '@mui/icons-material/CasesRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import AppColors from 'AppColors';

export const generalMenuItems: DrawerSection = {
  label: 'General',
  icon: <AnalyticsOutlinedIcon sx={{ color: AppColors.AAA_LIGHT_GRAY }} />,
  items: [
    {
      title: 'Dashboard',
      icon: <DashboardIcon />,
      path: '/dashboard',
      redirect: true,
    },
    {
      path: '/cases',
      title: 'Cases',
      redirect: true,
      action: () => console.log('Sidebar action clicked'),
      icon: <CasesRoundedIcon />,
    },
    {
      path: '/credit-cards',
      title: 'Credit Cards',
      redirect: true,
      icon: <CreditCardIcon />,
      notAllowedRoles: ['CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    },
  ],
};

export const financeMenuItems: DrawerSection = {
  label: 'Finance',
  icon: <AttachMoneyIcon sx={{ color: AppColors.AAA_LIGHT_GRAY }} />,
  items: [
    {
      path: '/bill-requests',
      title: 'Bill Requests',
      redirect: true,
      icon: <PaymentIcon />,
      notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    },
    {
      title: 'Payment Forecasts',
      path: '/payment-forecasts',
      icon: <ForecastIcon />,
      redirect: true,
      notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    },
    {
      title: 'Payment Requests',
      path: '/payment-requests',
      icon: <FinancialIcon />,
      redirect: true,
      notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    },
    // {
    //   title: 'Reports',
    //   path: '/reports',
    //   icon: <AssignmentTurnedInOutlinedIcon />,
    //   redirect: true,
    //   notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    // },
  ],
};

export const managementMenuItems: DrawerSection = {
  label: 'Management',
  icon: <SettingsIcon sx={{ color: AppColors.AAA_LIGHT_GRAY }} />,
  items: [
    {
      title: 'Clients & Programs',
      path: '/setup/clients',
      action: () => console.log('Sidebar action clicked'),
      icon: <PeopleAltRoundedIcon />,
      redirect: true,
      notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    },
    {
      title: 'Clubs & Vendors',
      path: '/setup/vendors',
      action: () => console.log('Sidebar action clicked'),
      icon: <StoreRoundedIcon />,
      redirect: true,
      notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    },
    {
      title: 'Service Items',
      path: '/setup/service-items',
      action: () => console.log('Sidebar action clicked'),
      icon: <HandymanRoundedIcon />,
      redirect: true,
      notAllowedRoles: ['NATIONAL_CUSTOMER_RELATION', 'CLUB_ADMIN', 'CLUB_RAP_RECONCILER_ANALYST'],
    },
  ],
};

export const useActiveItem = () => {
  const location = useLocation();

  return useMemo(
    () =>
      [...generalMenuItems.items, ...financeMenuItems.items, ...managementMenuItems.items]
        .flatMap((item) => (item.children ? [...item.children] : item))
        .find((item) => location.pathname.includes(item.path ?? 'unmatched')),
    [location],
  );
};
